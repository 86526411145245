import logo from './nibbles-small.png';
import './App.css';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Roller } from 'react-spinners-css';

function App() {
  const [resultVisible, setResultVisible] = useState(false);
  const [result, setResult] = useState("");
  const [pristine, setPristine] = useState(true);

  const config = {};
  const generateAnswer = () => {
    setResultVisible(false);
    setResult(Math.random() > 0.5);
    setResultVisible(true);
  }
  const handlers = useSwipeable({
    onSwiped: generateAnswer,
    onTap: () => setResultVisible(false),
    onSwiping: () => {
      setPristine(false);
      setResultVisible(false)
    },
    ...config,
  });

  const loadingSpinner = (
    <div className="loadingContainer">
      <Roller color="#c18122" />
    </div>
  )



  return (
    <div className="container">
      <h1>Nibbles says...</h1>
      <p>(stroke him)</p>
      <button className="button"
        onClick={() => generateAnswer()}
        {...handlers}
      >
        <img src={logo} className="nibblesImage" alt="logo" />
      </button>
      { resultVisible ? (<div className={`result ${result ? 'yes' : 'no'}`}>{result ? "Yes" : "No"}</div>) : (pristine || loadingSpinner)}
    </div>
  );
}

export default App;
